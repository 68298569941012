import { Modal, Row, Button, Col, DatePicker, Checkbox } from 'antd';
import style from './index.module.scss';
import { CloseSvg } from 'utils/svgs';
import './index.scss';
import { useState } from 'react';
// import { hasText } from 'utils/utils';
import dayjs from 'dayjs';

export default function DatePickerModel({
	open,
	onCancel,
	onSubmitEndDate,
	onChangeFormData,
	isFirstPayPeriod,
	closingDate,
	formData,
	isLoading,
}: Props) {
	const [hasErrorStart, setHasErrorStart] = useState(false);
	const [hasErrorEnd, setHasErrorEnd] = useState(false);

	const handleChangeForm = (key: string, value: any) => {
		onChangeFormData && onChangeFormData(key, value);
	};

	const handleCancel = () => {
		setHasErrorStart(false);
		setHasErrorEnd(false);
		onCancel && onCancel();
	};

	const handleSubmit = () => {
		if (hasErrorStart || hasErrorEnd) {
			return;
		}

		if (!formData.startDate) {
			setHasErrorStart(true);
			return;
		}

		if (!formData.endDate) {
			setHasErrorEnd(true);
			return;
		}

		onSubmitEndDate && onSubmitEndDate();
	};

	return (
		<>
			<Modal
				open={open}
				onCancel={handleCancel}
				okText={'Save'}
				closable={false}
				footer={null}
				className="date-picker-modal profile__popup"
			>
				<Row className={style['date-picker-modal__header']}>
					<div className="userDetailsTitle">
						<b>Create PayPeriod</b>
					</div>
					<div
						className={style['date-picker-modal__header-delete']}
						onClick={handleCancel}
					>
						<CloseSvg />
					</div>
				</Row>
				<hr />
				<div className="date-picker-modal__body">
					<Row style={{ marginBottom: '10px' }}>
						<Col xs={24} md={8} lg={8} sm={8}>
							{isFirstPayPeriod ? (
								<p>Select Start Date*</p>
							) : (
								<p>Start Date*</p>
							)}
						</Col>
						<Col xs={24} md={16} lg={16} sm={16}>
							<DatePicker
								size="large"
								format={'MM/DD/YYYY'}
								style={{ width: '100%' }}
								value={formData?.startDate || null}
								onChange={(date) => {
									handleChangeForm('startDate', date);
								}}
								status={hasErrorStart ? 'error' : ''}
								// onBlur={(e: any) => {
								// 	setHasErrorStart(!hasText(e.target.value));
								// }}
								disabled={!isFirstPayPeriod}
								// defaultPickerValue={
								// 	closingDate && dayjs(closingDate, 'MM/DD/YYYY')
								// }
								disabledDate={(current) => {
									return (
										closingDate &&
										current &&
										current < dayjs(closingDate, 'MM/DD/YYYY')
									);
								}}
							/>
						</Col>
					</Row>
					<Row style={{ marginBottom: '10px' }}>
						<Col xs={24} md={8} lg={8} sm={8}>
							<p>Select End Date*</p>
						</Col>
						<Col xs={24} md={16} lg={16} sm={16}>
							<DatePicker
								size="large"
								format={'MM/DD/YYYY'}
								style={{ width: '100%' }}
								disabledDate={(current) => {
									return (
										formData.startDate &&
										current &&
										current < dayjs(formData.startDate)
									);
								}}
								value={formData?.endDate || ''}
								onChange={(date) => {
									handleChangeForm('endDate', date);
									setHasErrorEnd(false);
								}}
								status={hasErrorEnd ? 'error' : ''}
								defaultPickerValue={
									formData.startDate ? dayjs(formData.startDate) : undefined
								}
								// onBlur={(e: any) => {
								// 	setHasErrorEnd(!hasText(e.target.value));
								// }}
								// defaultPickerValue={
								// 	formData.startDate && dayjs(formData.startDate)
								// } //TODO: this one is deprecated need to find correct one ASAP
							/>
						</Col>
					</Row>
					{!isFirstPayPeriod ? (
						<Row>
							<Checkbox
								onChange={(e) =>
									handleChangeForm(
										'copyEmployeeCostValueFromPriorPeriod',
										e.target.checked
									)
								}
								checked={formData?.copyEmployeeCostValueFromPriorPeriod}
							>
								Copy Employee Cost Values From Prior Pay Period
							</Checkbox>
						</Row>
					) : null}
					{closingDate &&
					formData?.endDate &&
					dayjs(closingDate) > dayjs(formData?.endDate) ? (
						<Row style={{ marginBottom: '10px' }}>
							<Col xs={24} md={8} lg={8} sm={8}>
								<p>QuickBooks Closing Date</p>
							</Col>
							<Col xs={24} md={16} lg={16} sm={16}>
								<p>{dayjs(closingDate).format('MM/DD/YYYY')}</p>
							</Col>
						</Row>
					) : null}
					{!closingDate ? (
						<Row style={{ marginBottom: '10px', color: 'red' }}>
							<p>Please update the QuickBooks closing date.</p>
						</Row>
					) : null}
				</div>
				<hr />
				<div className="date_picker_modal_footer">
					<Row justify="start" className="footerbtns" gutter={16}>
						<Col xs={12} md={7} lg={7} sm={8}>
							<Button className="cancel" block={true} onClick={handleCancel}>
								Cancel
							</Button>
						</Col>
						<Col xs={12} md={7} lg={7} sm={8}>
							<Button className={`save`} onClick={() => handleSubmit()}>
								{isLoading ? (
									<img src="/assets/gifs/loading-black.gif" height={40} />
								) : (
									'Create'
								)}
							</Button>
						</Col>
					</Row>
				</div>
			</Modal>
		</>
	);
}

interface Props {
	open: boolean;
	formData: any;
	closingDate: any;
	isFirstPayPeriod: boolean;
	onCancel: () => void;
	onChangeFormData: (key: any, value: any) => void;
	onSubmitEndDate: () => void;
	isLoading: boolean;
}
