import { Next, Previous } from 'utils/svgs';
import styles from './index.module.scss';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { AppDispatch } from 'redux/store';
import {
	payPeriodOptionsAction,
	savePayPeriod,
} from 'redux/action/payPeriodAction';
import moment from 'moment';
import DatePickerModel from './DatePickerModel';
import { toastText } from 'utils/utils';
import dayjs from 'dayjs';
import { useSearchParams } from 'react-router-dom';

export default function PayPeriodFilter({
	onChangePayPeriodId,
	payPeriodId,
	createNewPayPeriod = false,
	defaultModalOpen = false,
}: PayPeriodFilterProps) {
	const dispatch = useDispatch<AppDispatch>();

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [queryParams, setQueryParams] = useSearchParams();
	const [payPeriodIndex, setPayPeriodIndex] = useState(0);
	const [isLoading, setIsLoading] = useState(false);

	const payPeriods = useSelector((state: any) => state?.payPeriods?.optionData);

	const [openDatePicker, setOpenDatePicker] = useState(false);

	const [formData, setFormData] = useState<any>({
		startDate: null,
		endDate: null,
		copyEmployeeCostValueFromPriorPeriod: false,
	});

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { closingDate } = useSelector((state: any) => state?.closingDate);

	const [qboClosingDate, setQboClosingDate] = useState<any>(null);

	const handleChangeForm = (key: string, value: any) => {
		if (key === 'startDate') {
			setFormData({ startDate: value, endDate: null });
			return;
		}
		setFormData({ ...formData, [key]: value });
	};

	const [isFirstPayPeriod, setIsFirstPayPeriod] = useState(false);

	const { selectedCompanyDetails } = useSelector(
		(state: any) => state?.companies
	);

	useEffect(() => {
		if (selectedCompanyDetails) {
			dispatch(payPeriodOptionsAction());
		}
	}, [selectedCompanyDetails]);

	useEffect(() => {
		if (closingDate) {
			setQboClosingDate(dayjs(closingDate));
			return;
		}
		setQboClosingDate(null);
	}, [closingDate]);

	useEffect(() => {
		if (payPeriodId) {
			const index = payPeriods.findIndex(
				(singlePayPeriod: any) => singlePayPeriod.id === payPeriodId
			);
			setPayPeriodIndex(index > 0 ? index : 0);
			return;
		}
		setPayPeriodIndex(-1);
	}, [payPeriodId, payPeriods]);

	useEffect(() => {
		if (defaultModalOpen && payPeriodIndex > -1) {
			nextPayPeriodHandler();
		}
	}, [defaultModalOpen, payPeriodIndex]);

	const previousPayPeriodHandler = () => {
		if (payPeriods && payPeriods.length > 0) {
			const index = payPeriods.findIndex(
				(singlePayPeriod: any) => singlePayPeriod.id === payPeriodId
			);

			if (payPeriods[index - 1]) {
				onChangePayPeriodId && onChangePayPeriodId(payPeriods[index - 1]?.id);
				// setPayPeriodIndex(index);
			}
		}
	};

	const nextPayPeriodHandler = () => {
		if (payPeriods && payPeriods.length > 0) {
			const index = payPeriods.findIndex(
				(singlePayPeriod: any) => singlePayPeriod.id === payPeriodId
			);
			if (payPeriods[index + 1]) {
				onChangePayPeriodId && onChangePayPeriodId(payPeriods[index + 1]?.id);
				// setPayPeriodIndex(index);
			}

			if (!payPeriods[index + 1] && createNewPayPeriod) {
				setFormData({
					startDate: dayjs(payPeriods[payPeriodIndex].endDate).add(1, 'day'),
					endDate: null,
				});
				setIsFirstPayPeriod(false);
				setOpenDatePicker(true);
			}

			return;
		}

		if ((!payPeriods || !payPeriods.length) && createNewPayPeriod) {
			setFormData({ startDate: null, endDate: null });
			setIsFirstPayPeriod(true);
			setOpenDatePicker(true);
		}
	};

	const handleSavePayPeriod = async () => {
		setIsLoading(true);
		// if (!closingDate) {
		// 	return;
		// }

		const _data = {
			companyId: localStorage.getItem('companyId'),
			startDate: formData.startDate.$d,
			endDate: formData.endDate.$d,
			copyEmployeeCostValueFromPriorPeriod:
				formData.copyEmployeeCostValueFromPriorPeriod,
		};

		dispatch(savePayPeriod(_data));
		setOpenDatePicker(false);
		toastText(
			'Pay-Period creation request has been sent successfully',
			'success'
		);
		/* if (res?.payload?.id) {
			onChangePayPeriodId && onChangePayPeriodId(res?.payload?.id);
			await dispatch(payPeriodOptionsAction());
			setOpenDatePicker(false);
			const query: any = {
				payPeriodId: res?.payload?.id,
			};
			setQueryParams(query);
		}

		if (res.payload.error) {
			toastText(res.payload.error.message, 'error');
		} */
		setIsLoading(false);
	};

	return (
		<>
			<div className={styles['search-filter-main-pay-period']}>
				<div
					className={`${styles[`search-filter-main-prev`]} ${
						!payPeriodId || payPeriodIndex <= 0 ? 'pointer-event-none' : ''
					}`}
					onClick={previousPayPeriodHandler}
				>
					<Previous />
					Prev
				</div>
				<div
					className={`${styles['payPeriod-display']} ${
						payPeriods[payPeriodIndex] ? '' : styles['gray']
					}`}
				>
					{payPeriods[payPeriodIndex]
						? `${moment(payPeriods[payPeriodIndex].startDate).format(
								'MM/DD/YYYY'
						  )} - ${moment(payPeriods[payPeriodIndex].endDate).format(
								'MM/DD/YYYY'
						  )}`
						: 'Select PayPeriod'}
				</div>
				{!createNewPayPeriod ? (
					<div
						className={`${styles[`search-filter-main-next`]} ${
							payPeriods.length - 1 === payPeriodIndex || !payPeriods.length
								? 'pointer-event-none'
								: ''
						}`}
						onClick={nextPayPeriodHandler}
					>
						Next
						<Next />
					</div>
				) : (
					<div
						className={`${styles[`search-filter-main-next`]}`}
						onClick={nextPayPeriodHandler}
					>
						Next
						<Next />
					</div>
				)}
			</div>
			<DatePickerModel
				open={openDatePicker}
				closingDate={qboClosingDate}
				onCancel={() => {
					setOpenDatePicker(false);
					const query: any = {};
					if (queryParams.get('payPeriodId')) {
						query.payPeriodId = queryParams.get('payPeriodId');
					}
					setQueryParams(query);
				}}
				onSubmitEndDate={() => handleSavePayPeriod()}
				isLoading={isLoading}
				formData={formData}
				onChangeFormData={(key, value) => {
					handleChangeForm(key, value);
				}}
				isFirstPayPeriod={isFirstPayPeriod}
			/>
		</>
	);
}

interface PayPeriodFilterProps {
	payPeriodId?: string | null;
	onChangePayPeriodId?: (payPeriodId: string) => void;
	createNewPayPeriod?: boolean;
	defaultModalOpen?: boolean;
	handleShowPayPeriodCreationRefresh?: any;
	showPayPeriodCreationRefresh?: any;
}
